import { Injectable } from '@angular/core';
import { MessageService } from '../message/message.service';
import { throwError } from 'rxjs';
// import { AuthService } from '../../../authentication/authentication.module';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

/**
 * Service to created to handle common errors returned from backend
 */

export class ErrorHandlerService {

  messages!: string;
  constructor(
    private messageService: MessageService,
    // private authService: AuthService,
    private router: Router
  ) { }

  /**
   * @description handles error for every backend api
   * and shows error toaster message and throws error.
   * @param error - Error
   */
  handleError(error : any, messageObj ?: any , isShow : boolean | undefined = true) {

    if (!messageObj || !messageObj.errorMessage) {
      switch (error.status) {
        case 401:
          {
            this.messages = 'unauthorizedAccess';
            if (localStorage.getItem('login_info') == '') {
              this.router.navigate(['/unauthorizedAccess']);
            }
            break;
          }
        case 500:
          {
            if (error && error.error.ResponseException) {
              this.messages =
                (error.error.ResponseException.ValidationErrors && error.error.ResponseException.ValidationErrors[0])
                  ? 'Somethingwentwrong.'
                  : error.error.ResponseException.ExceptionMessage
                    ? error.error.ResponseException.ExceptionMessage
                    : 'Somethingwentwrong.';
            } else {
              this.messages = 'Somethingwentwrong.';
            }
            break;
          }
        case 421: this.messages = 'invalidCountry'; break;
        case error.status >= 400 && error.status <= 499:
          this.messages = 'Somethingwentwrong.';
          break;
        default:
          this.messages = 'Somethingwentwrong.';
          break;
      }
    } else {
      this.messages = messageObj.errorMessage;
    }
    if ((!messageObj || messageObj.showMessage === undefined || messageObj.showMessage) && isShow) {
      this.messageService.showErrorMessage(this.messages);
    }
    return throwError(() => this.messages);
  }
}
